@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';

@import url("./stylesheets/slider.scss");

@font-face {
  font-family: 'Bandiko trial';
  src: url('./fonts/bandiko-trial.regular-use-it.otf');
}

@font-face {
  src: url('../src//fonts/PrioriSansOT-Bold.otf');
  font-family: 'Priori Sans OT';
  font-weight: 700;
}

@font-face {
  src: url('../src//fonts/PrioriSansOT-Regular.otf');
  font-family: 'Priori Sans OT';
  font-weight: 400;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* font-family: 'bandiko-reg'; */
  font-weight: normal;
}

a-scene {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

/* Navbar */
.mainHeader {
  background: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  padding: 0 !important;
  height: 50px;
}

.box-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullscreen-button {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  z-index: 999;
}

.box-user {
  width: 200px;
  height: 200px;
  background: skyblue;
  overflow: hidden;
}

/* .whenSC.mainHeader{background: rgb(16 16 16 / 50%);} */

/* opacity 75% */
ul {
  list-style-type: none;
  margin-bottom: 0px;
}

ul li {
  margin-right: 30px;
}

ul li a {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  font-variant: all-small-caps;

  text-decoration: none;
  float: left;
  /* font-size: 14px; */
  color: rgb(255 255 255 / 75%);
  /* line-height: 16px; */
  padding: 13px 0;
}

ul li a.active {
  color: #fff;
}

.theme-btn {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;

  float: right;
  /* font-size: 14px; */
  color: #fff;
  padding: 4px 21.5px;
  /* border: 0; */
  /* line-height: 16px; */
  margin-top: 14px;
  border: solid 2px transparent;
  letter-spacing: 1px;
  border-radius: 5px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #a40000, #fdbe66);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000 inset;
}

/* end Navbar */
.main-wrapper {
  display: flex;
  position: absolute;
  // top: 15%;
  height: 600px;
  /* width: 700px !important; */
  /* transform: translateX(-50%,-50%); */
  z-index: 1;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.fPart {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 52.5px 30px;
  gap: 15px;
  border-radius: 3.90875px;
  flex: none;
  order: 0;
  flex-grow: 0;
  /* width:100%; */
  /* height: 349.13px; */

  &.first {
    background: radial-gradient(
      50.14% 50% at 50% 50%,
      rgba(15, 13, 13, 0.7) 0%,
      rgba(15, 13, 13, 0) 100%
    );
  }

  &.second {
    padding: 52.5px 35px;
    background: radial-gradient(
      47.51% 45.83% at 50% 50%,
      rgba(15, 13, 13, 0.8) 0%,
      rgba(15, 13, 13, 0) 100%
    );
  }

  &.third {
    background: radial-gradient(
      50.1% 50% at 50% 50%,
      rgba(15, 13, 13, 0.8) 0%,
      rgba(15, 13, 13, 0.8) 0.01%,
      rgba(15, 13, 13, 0) 100%
    );
  }

  &.fourth {
    max-width: 701px;
    padding: 45px 60px;
    background: radial-gradient(
      44.65% 49.98% at 50% 50%,
      rgba(15, 13, 13, 0.8) 0%,
      rgba(15, 13, 13, 0) 100%
    );
  }

  &.fifth {
    background: radial-gradient(
      50.79% 50% at 50% 50%,
      rgba(15, 13, 13, 0.8) 0%,
      rgba(15, 13, 13, 0.592486) 51.45%,
      rgba(15, 13, 13, 0) 100%
    );
  }

  &.sixth {
    background: radial-gradient(
      50.11% 50.16% at 50% 50%,
      rgba(15, 13, 13, 0.6) 0%,
      rgba(15, 13, 13, 0) 100%
    );
  }

  .partner-logos {
    display: flex;
    gap: 17.37px;
    justify-content: center;
    align-items: center;

    .img-fluid {
      height: 16px;
      width: 76px;

      &.first {
        width: 88.47px;
      }
    }
  }
}
.arrow {
  color: white;
}
.left {
  position: absolute;
  top: 50%;
  left: -80px;
  z-index: 99;
}
.right {
  position: absolute;
  top: 50%;
  right: -80px;
  z-index: 99;
}
.fPart img {
  display: block;
  width: 180px;
  margin: auto;
}
.fade_rule {
  height: 2px;
  width: 266px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 0%,
    #fdbe66 50%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background-image: -o-linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 0%,
    #fdbe66 50%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background-image: -moz-linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 0%,
    #fdbe66 50%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.1) 0%,
    #fdbe66 50%,
    rgba(0, 0, 0, 0.1) 100%
  );

  &.nation {
    width: 635px;
  }

  &.discover {
    width: 175px;
  }

  &.the-future {
    width: 582px;
  }

  &.partners {
    width: 265.79px;
    margin: 15px 0;
  }
}

.fPart .title {
  display: flex;
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 2px;
  color: #ffffff;
  margin-bottom: 0px;
  justify-content: center;
  align-items: center;
}

.fPart .title-desc {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 2px;
  color: #ffffff;
  width: 67%;
}

.fPart .p1 {
  text-align: center;
  font-size: 33px;
  /* letter-spacing: 1px; */
  color: #fff;
  /* text-transform: uppercase; */
  opacity: 0.9;
  font-family: 'Priori Sans OT';
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.02em;
  font-weight: 700;
}

.fPart h1 {
  font-family: 'Bandiko trial';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 42px;
  letter-spacing: 6px;
  background: linear-gradient(92.66deg, #fdbe66 22.21%, #a40000 77.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  margin: 0;

  /* border: 0.75px solid rgba(0, 0, 0, 0.25); */
  text-shadow: 0px 1.5px 2.25px rgba(0, 0, 0, 0.15);
  /* font-weight: bold; */
}

.fPart strong {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.35585px;
  text-transform: capitalize;
  color: #ffffff;
}

.fPart .p2 {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  opacity: 0.9;
  margin: 0;
}

/* .test {
  position: absolute;
  top:50%;
  left:10%;
  z-index: 99;
  color: white !important;
} */
.testone {
  position: absolute;
  top: 50%;
  right: 10%;
  z-index: 99;
  color: white !important;
}

.fPart button {
  position: relative;
  font-size: 14px;
  color: #fff;
  padding: 8px 18px;
  line-height: 16px;
  border: solid 2px transparent;
  letter-spacing: 1px;
  border-radius: 5px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #fdbe66, #a40000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000 inset;
  z-index: 1;
}

.cursor {
  cursor: pointer;
}

.btnDiv-c {
  position: relative;
  width: 138px;
  font-family: 'priorisans';
  margin: auto;
}

.btnDiv-c::after {
  position: absolute;
  top: 4px;
  left: 4px;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #fdbe66, #a40000);
}

.number {
  font-family: 'priorisans';
  color: #fff;
}

/* second section */
.fPart .wrapper {
  display: flex;
  flex-direction: column;
  gap: 8.79px;
  align-items: center;
}

.fPart .wrapper .parnet-box {
  /* opacity: 0.8; */
  display: flex;
  padding: 14.66px 18px 15.57px;
  /* background: #340F0F; */
  background: rgba(24, 18, 18, 0.7);
  border-radius: 7.32891px;
  /* padding: 0px 18px; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border-radius: 8px; */
  color: #fff !important;
  /* width: 500px; */
  width: 566.16px;
  gap: 5.5px;
}

.fPart .wrapper .parnet-box .box-title {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 3.75px;
  text-transform: uppercase;
  color: #ffffff;
  height: 32.06px;
  margin: 0;
}

/* Four section */
.web-number {
  color: #fff;
  font-size: 18px;
  font-family: 'Priori Sans OT';
}

.future-detail-title {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 27px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 2.93px;
}

.game-desc {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  opacity: 0.8;
  height: 21.07px;
}

.more-detail {
  font-family: 'Priori Sans OT';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0;
}

.media-box-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5.62px;
}

.media-box {
  display: flex;
  flex-direction: column;
  background: rgba(24, 18, 18, 0.7);
  border-radius: 7.32891px;
  width: 500px;
  padding: 8.79469px 17.5894px;
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .fPart h1 {
    font-size: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .fPart .p2 {
    font-size: 14px !important;
  }

  .future-detail-title {
    text-align: start !important;
  }
}

@media only screen and (max-width: 425px) {
  .fPart h1 {
    font-size: 21px !important;
  }
  .fade_rule {
    width: 16em !important;
  }
  .left {
    left: 0px;
  }
  .right {
    right: 0px;
  }
  // .main-wrapper {
  //   // top: -2% !important;
  // }
  .fPart .wrapper .parnet-box {
    width: 90% !important;
    padding: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .media-box {
    width: 100%;
  }
  .fPart .title-desc {
    width: 100%;
    font-size: 25px;
  }

  .game-desc {
    display: -webkit-box;
    width: auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fPart strong {
    font-size: 17px !important;
  }

  .web-number {
    font-size: 19px !important;
  }

  .fPart .wrapper .parnet-box .box-title {
    font-size: 16px !important;
    line-height: 16px !important;
    height: 16px !important;
  }

  .game-desc {
    font-size: 15px;
  }

  .icon-img {
    width: 40px !important;
    height: 40px !important;
  }
}

@media only screen and (max-width: 992px) {
  // .main-wrapper {
  //   // top: 9%;
  //   // height: auto;
  // }
  .fPart.active {
    width: 100%;
  }

  .game-desc {
    width: auto;
  }

  .fPart .p1 {
    font-size: 17px;
  }

  .fPart h1 {
    font-size: 27px;
    text-align: center;
  }

  .fPart strong {
    font-size: 23px;
    text-align: center;
    width: 100%;
    float: left;
  }

  .fPart .p2 {
    width: 65%;
    padding: 0px;
    font-size: 17px;
    // margin-bottom: 8px;
    line-height: 16px;
  }

  .more-detail {
    font-size: 17px;
    line-height: 17px;
  }

  .future-detail-title {
    font-size: 15px;
    line-height: 15px;
    text-align: initial;
  }

  .navbar-mobile {
    display: flex;
    background: white !important;
    width: 40px;
    height: 37px;
    justify-content: center;
    align-items: center;
  }

  .web-number {
    font-size: 27px;
  }
}
