.swiper {
  pointer-events: none;
}

.swiper-slide {
  display: flex !important;
  align-items: center;
  user-select: none;

  &.swiper-slide-active {
    .carousel-item {
      .fPart {
        margin: auto;
      }
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #fff !important;
  pointer-events: all;
  // hide after selector
  &:after {
    display: none;
  }
}
